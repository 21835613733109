/*
Промисы, асинхронные функции
• проблема колбэков
• пример с анимациями
• думаем как решать проблему, пишем свой промис
• Что такое промис
• wait промис
• await промиса в асинхронной функции
*/
import PromiseIntro from './PromiseIntro'

export default [
  {
    label: '!',
    component: PromiseIntro
  }
]
// https://learn.javascript.ru/async
