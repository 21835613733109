<template>
  <h2>Промисы, асинхронные функции</h2>
  <p><a href="https://learn.javascript.ru/async" class="link" target="_blank">Читать здесь</a></p>
</template>

<script>
export default {
  props: ['task']
}
</script>
